<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row gutter-b">
      <div class="col-xxl-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Form Broadcast Message</h6>
          </template>
          <div class="mb-4">
            <h6 class="mb-1">Judul</h6>
            <b-form-input
              v-model="title"
              placeholder="Masukan judul brodcast"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h6 class="mb-1">Pesan</h6>
            <b-form-textarea
              id="textarea-rows"
              v-model="message"
              placeholder="Masukan area brodcast"
              rows="8"
              style="height: 116px"
            ></b-form-textarea>
          </div>
          <template v-slot:footer>
            <b-button variant="primary" @click="handleSubmit">Submit</b-button>
          </template>
        </b-card>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "CreateBrodcast",
  data() {
    return {
      selected: null,
      title: "",
      message: "",
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Brodcast" },
    ]);
  },
  methods: {
    async handleSubmit() {
      try {
        const payload = {
          user_id: "",
          title: this.title,
          message: this.message,
          type: "announcement",
          extra: "",
          json: true,
        };
        await this.$axios.post(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/notification`,
          payload
        );
        this.$bvToast.toast("Successfully broadcast", {
          title: "Successs",
          variant: "success",
          solid: true,
        });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>
<style lang="scss"></style>
